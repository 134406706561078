import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CrudService } from '../components/services/crud.service';
import { environment } from 'src/environments/environment';
import { async } from 'rxjs/internal/scheduler/async';
import Swal from 'sweetalert2';

import {Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ignoreElements } from 'rxjs/operators';


declare var JitsiMeetExternalAPI: any;

@Component({
    selector: 'app-jitsi',
    templateUrl: './jitsi.component.html',
    styleUrls: ['./jitsi.component.css']
})
export class JitsiComponent implements OnInit, AfterViewInit {
    @ViewChild('idCorsistaMeet') idCorsistaMeet: ElementRef;

    domain: string;
    room: any;
    options: any;
    api: any;
    user: any;

    // For Custom Controls
    isAudioMuted = true;
    isVideoMuted = false;
    isVideoShared = false;
    isChat = false;
    isHandMuted = false;

    public idCorsista: string="";
    public nomeCognomeCorsista: string="";
    public emailCorsista: string="";
    public idCorsoSvolgimento: string="";
    public tokenMeet: string="";

    public isJoined:boolean=false;
    public isKickoutFromRoom: boolean=false;

    public elementMeet: Element;
    public isLoaded: boolean=false;

    public userIDMeet: string="";
    public isOnlyMember: boolean=false;

    public setTimeoutHandle: any;
    public setTimeoutHandleAutomatic: any;
    public arrayDetail: any;

    public disabledVideocamera: string;
    public canShowExtraControls: string="";

    constructor(
        private router: Router,
        private crudService: CrudService,
        private el: ElementRef,

        @Inject(DOCUMENT) private _document: HTMLDocument
    ) { }

    ngOnInit(): void {
        var serverMeet = document.getElementById('serverMeet') as HTMLInputElement;
        this.domain=serverMeet.value;

        var inputIdCorsista = document.getElementById('idCorsistaMeet') as HTMLInputElement;
        this.idCorsista=inputIdCorsista.value;

        var input = document.getElementById('nomeCognomeMeet') as HTMLInputElement;
        this.nomeCognomeCorsista=input.value;

        input = document.getElementById('emailCorsistaMeet') as HTMLInputElement;
        this.emailCorsista=input.value;

        var inputIdCorsoSvolgimento = document.getElementById('idCorsoSvolgimento') as HTMLInputElement;
        this.idCorsoSvolgimento=inputIdCorsoSvolgimento.value;

        input = document.getElementById('tokenMeet') as HTMLInputElement;
        this.tokenMeet=input.value;

        input = document.getElementById('abilitaVideocamera') as HTMLInputElement;
        this.disabledVideocamera=input.value;

        input = document.getElementById('canShowExtraControls') as HTMLInputElement;
        this.canShowExtraControls=input.value;

        var inputNomeAula = document.getElementById('nomeAula') as HTMLInputElement;

        var inputCorsoHeader = document.getElementById('idCorsoHeader') as HTMLInputElement;
        var inputDurata = document.getElementById('durata') as HTMLInputElement;
        var inputLinkAula = document.getElementById('linkAula') as HTMLInputElement;
        var inputDataOra = document.getElementById('dataOra') as HTMLInputElement;
        var inputDataOraFull = document.getElementById('dataOraFineFull') as HTMLInputElement;

        this.arrayDetail={'idCorsista': inputIdCorsista.value,
                          'idCorsoSvolgimento': inputIdCorsoSvolgimento.value,
                          'idCorsoHeader': inputCorsoHeader.value,
                          'durata': inputDurata.value,
                          'nome_aula': inputNomeAula.value,
                          'link_aula': inputLinkAula.value,
                          'dataInizio': inputDataOra.value,
                          'dataFine': inputDataOraFull.value};

        //this.api.executeCommand('toggleLobby', true);

        this.room = inputNomeAula.value;
        this.user = {
            name: this.nomeCognomeCorsista
        }

        this.elementMeet=document.querySelector('#jitsi-iframe');

        this.options = {
            roomName: this.room,
            lang: 'it',
            height: '100%',
            configOverwrite: {
                prejoinPageEnabled: false,
                startWithAudioMuted: true,
                disableDeepLinking: true,
                enableNoisyMicDetection: true,
            },
            interfaceConfigOverwrite: {
                APP_NAME: 'Discentya',
                SHOW_JITSI_WATERMARK: false,
                DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
                TOOLBAR_ALWAYS_VISIBLE: false,
                //TOOLBAR_BUTTONS: ['microphone', 'camera', 'profile', 'settings', 'raisehand', 'videoquality', 'hangup'],
                TOOLBAR_BUTTONS: [''],
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            },
            parentNode: this.elementMeet,
            userInfo: {
                displayName: this.user.name,
                email: this.emailCorsista
            }
        }


        //console.log(this.domain+this.tokenMeet+"/");
        this.api = new JitsiMeetExternalAPI(this.domain+this.tokenMeet+"/", this.options);

    }

    ngAfterViewInit(): void {


        /*
        setInterval(() => {
            console.log(this.api);
        }, 5000);
        */
        /*
        setInterval(() => {
            this.api.getRoomsInfo().then(rooms => {
                console.log(rooms);
            });
            console.log(this.api.getNumberOfParticipants());
            this.getParticipants();
        }, 1000);
        */


        /*
        this.setTimeoutHandle = setTimeout(() => {
            this.isLoaded=true;
        }, 3000);
        */

        this.initEvents();
    }

    initDevice() {
        this.api.isAudioDisabled().then(disabled => {
            if(disabled)
                this.isAudioMuted=true;
        });

        this.api.isAudioMuted().then(muted  => {
            if(!muted)
                this.isAudioMuted=true;
        });

        this.api.isVideoMuted().then(muted  => {
            this.isVideoMuted=muted;
            /*
            if(!muted)
                this.executeCommand('toggleVideo');
            */
        });

        this.api.isVideoAvailable().then(available  => {
            if(!available)
                this.isVideoMuted=true;
        });

        this.api.getRoomsInfo().then(rooms => {
        })

    }

    initEvents() {
        this.api.addEventListeners({
            readyToClose: this.handleClose,
            participantLeft: this.handleParticipantLeft,
            participantJoined: this.handleParticipantJoined,
            videoConferenceJoined: this.handleVideoConferenceJoined,
            audioMuteStatusChanged: this.handleMuteStatus,
            videoMuteStatusChanged: this.handleVideoStatus,
            screenSharingStatusChanged: this.handleScreenStatus,
            participantKickedOut: this.handleParticipantKickedOut,
            moderationParticipantRejected: this.handleModerationParticipantRejected,
            moderationParticipantApproved: this.handleModerationParticipantApproved,
            errorOccurred: this.handleErrorOccurred
        });

        setTimeout(() => {
            this.api.addEventListeners({
                videoConferenceLeft: this.handleVideoConferenceLeft,
            })
        }, 5000);

        /*
        if(!this.userIDMeet)
            this.userIDMeet=this.nomeCognomeCorsista;
        */

    }

    salvaTimeOut() {
        if(this.idCorsista!="" && this.idCorsoSvolgimento!="") {
            this.crudService.api_setTImeoutCorsista(this.idCorsista, this.idCorsoSvolgimento).subscribe(productData => {
                this.chiudiMeet();
            },(err)=>{
                //console.log(err);
                }, ()=>{
            });
        }
    }

    salvaTimeOutAutomatico() {
        if(this.idCorsista!="" && this.idCorsoSvolgimento!="") {
            this.crudService.api_setTImeoutCorsista(this.idCorsista, this.idCorsoSvolgimento).subscribe(productData => {
            },(err)=>{
                //console.log(err);
                }, ()=>{
            });
        }
    }

    /*
    onConnectionFailed(error) {
        console.log("joooo."+error);

    }
    */

    entraInAula() {
        this.crudService.set_entraInAulaCorsista(this.arrayDetail).subscribe(responseData => {
            if(responseData==1) {}
        });

        this.setTimeoutHandleAutomatic = setInterval(() => {
            this.salvaTimeOutAutomatico();
        }, 300000);
    }

    handleErrorOccurred = (detail) => {
        //console.log("error: "+detail.error.name);

        switch (detail.error.name) {
            case 'conference.connectionError.accessDenied':
                this.corsistaNonAccettato();
                break;

            case "conference.connectionError.membersOnly":
                this.isLoaded=true;
                this.isOnlyMember=true;

            default:
                break;
        }
    }

    handleClose = () => {
        console.log("handleClose");
    }

    handleParticipantKickedOut = (participant) => {
        this.isKickoutFromRoom=true;
    }

    handleModerationParticipantApproved = (participant) => {
        console.log("approvato...");
    }

    handleModerationParticipantRejected() {
        console.log("non accettato");
    }

    handleParticipantLeft =  (participant) => {
        console.clear();
        console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
        //const data = await this.getParticipants();


        //this.salvaTimeOut();
    }

    handleParticipantJoined = (participant) => {
        this.isOnlyMember=false;
        //this.initDevice();

        //this.isLoaded=false;
        clearTimeout(this.setTimeoutHandle);

        //if(!this.userIDMeet)
        this.userIDMeet = participant.id;

        this.getParticipants(participant, this.idCorsista);
        //console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
        //const data = await this.getParticipants();

        this.isJoined=true;
        this.entraInAula();

        //watermark leftwatermark
    }

    handleVideoConferenceJoined = (participant) => {
        this.initDevice();

        this.isLoaded=true;

        //this.isLoaded=false;
        //clearTimeout(this.setTimeoutHandle);

        //console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
        //const data = await this.getParticipants();

        this.isJoined=true;
    }


    handleVideoConferenceLeft = (participant) => {
        console.log("handleVideoConferenceLeft");
        //this.router.navigate(['/thank-you']);

        //if(this.isJoined)
        //    this.salvaTimeOut();
        //if(participant.id==this.userIDMeet)
        //this.chiudiMeet(participant);

    }

    handleMuteStatus = (audio) => {
        //console.log("handleMuteStatus", audio); // { muted: true }
    }

    handleVideoStatus = (video) => {
        //console.log("handleVideoStatus", video); // { muted: true }
    }

    handleScreenStatus = (status) => {
        //console.log('CUSTOM Screensharing status', status);
        this.isVideoShared=status.on;
    }

    getParticipants(participant, idCorsista) {
        console.clear();

        var arrayP=this.api.getParticipantsInfo();
        arrayP[arrayP.length - 1]['idCorsista']=idCorsista;
        console.log(arrayP[arrayP.length - 1])
            setTimeout(() => {
                console.log(this.api.getParticipantsInfo());
            }, 2500)
    }

    // custom events
    executeCommand(command: string) {
        if(command == 'hangup') {
            //console.log("Corsista Disconnesso: ");
            this.salvaTimeOut();
            return;
        }

        this.api.executeCommand(command);;

        if(command == 'toggleRaiseHand') {
            this.isHandMuted = !this.isHandMuted;
        }

        if(command == 'toggleAudio') {
            this.isAudioMuted = !this.isAudioMuted;
        }

        if(command == 'toggleVideo') {
            this.isVideoMuted = !this.isVideoMuted;
        }

        if(command == 'toggleChat') {
            this.isChat = !this.isChat;
        }

        /*
        if(command == 'toggleShareScreen') {
            this.isVideoShared = !this.isVideoShared;
        }
        */
    }

    corsistaNonAccettato() {
        var tk=document.getElementById('jitsi-iframe') as HTMLInputElement;
        tk.style.display="none";

        Swal.fire({
            title:'Respinto!',
            text: 'la tua richiesta di entrare in aula è stata respinta, riprova o contatta l\'amministrazione per ulteriori informazioni.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Riprova',
            allowOutsideClick: false,
         }).then((result) => {
            if (result.isConfirmed) {
                location.reload();
            }
        });
    }


    chiudiMeet() {
        var tk=document.getElementById('jitsi-iframe') as HTMLInputElement;
        tk.style.display="none";

        //this.router.navigate(['/thank-you']);
        location.reload();
    }

    /*
    api.addEventListener(‘participantRoleChanged’, function (event) {
        if(event.role === ‘moderator’) {
            api.executeCommand(‘toggleLobby’, true);
        }
    });
    */
}
