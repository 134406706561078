// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  numero_WhatsApp: "",
  numero_telefono: "tel:+390825784671",

  //text per personalizzare i messaggi
  canShowPagamentoRate: false,
  textShowPagamentoRate: "",

  canShowElencoDate: false,

  /*
  urlWeb: 'http://localhost/',
  urlMaterialeDidattico: 'http://localhost/assets/media/materiale-didattico/1/',
  urlImageCorso: 'http://localhost/assets/media/corso/',
  urlImageCorsoHD: 'http://localhost/assets/media/corso/hd/',
  urlImageVideoCorso: 'http://localhost/assets/media/corso/video/',
  web_api_url_base: 'http://localhost/api/',

  urlStampa: 'http://localhost/stampe/',

  urlAttestati: 'http://localhost/assets/media/certificate/corsisti/',
  urlAttestatiDaCreare: 'http://localhost/pages/certificate/',
  urlImagePartners: 'http://localhost/assets/media/partners/',
  */

  urlWeb: 'https://discentya.catimprese.academy/',
  urlMaterialeDidattico: 'https://discentya.catimprese.academy/assets/media/materiale-didattico/1/',
  urlImageCorso: 'https://discentya.catimprese.academy/assets/media/corso/',
  urlImageCorsoHD: 'https://discentya.catimprese.academy/assets/media/corso/hd/',
  urlImageVideoCorso: 'https://discentya.catimprese.academy/assets/media/corso/video/',
  web_api_url_base: 'https://discentya.catimprese.academy/api/',

  urlStampa: 'https://discentya.catimprese.academy/stampe/',

  urlAttestati: 'https://discentya.catimprese.academy/assets/media/certificate/corsisti/',
  urlAttestatiDaCreare: 'https://discentya.catimprese.academy/pages/certificate/',
  urlImagePartners: 'https://discentya.catimprese.academy/assets/media/partners/',


};

